<template>
  <div class="home">
    <Hero
      hash="video"
      :imgSrc="require('../assets/hero-image.png')"
      :imgSrc2x="require('../assets/hero-image@2x.png')"
      :link="require('../assets/kurzanleitung.pdf')">
    </Hero>

    <Heading>
      <template v-slot:heading>
        Der BYDUREON<sup>®</sup> BCise<sup>®</sup> im Überblick
      </template>
      <template v-slot:text>
        Ihr Arzt hat Sie auf BYDUREON<sup>®</sup> BCise<sup>®</sup> eingestellt. Sie haben möglicherweise bereits BYDUREON<sup>®</sup> erhalten und werden zu diesem Gerät wechseln oder Sie beginnen erstmalig mit der Therapie mit einem GLP-1 Rezeptoragonisten.
      </template>
    </Heading>

    <BigImage :imgSrc="require('../assets/bcise.jpg')" :imgSrc2x="require('../assets/bcise.jpg')" />

    <ImageAndText :imgSrc="require('../assets/teaser.jpg')" :imgSrc2x="require('../assets/teaser@2x.jpg')">
      <p>Ihr Arzt hat entschieden, BYDUREON<sup>®</sup> BCise<sup>®</sup> zu Ihrer Typ-2-Diabetes Behandlung hinzuzufügen. Typ-2-Diabetes ist eine Erkrankung, die sich im Laufe der Zeit verändern kann und somit auch neue Therapien oder eine Umstellung der Medikamente erfordert.</p>
      <p>BYDUREON<sup>®</sup> BCise<sup>®</sup> (Exenatid) wirkt in Ihrem Körper, um den Blutzuckerspiegel (Glukose) zu verbessern und Ihren HbA1c-Wert zu senken. Es handelt sich dabei nicht um Insulin, hilft Ihrem Körper aber, die richtige Menge Insulin zu produzieren, um den Blutzuckerspiegel zu erhalten.</p>
      <p>
        BYDUREON<sup>®</sup> BCise<sup>®</sup>:<br />
        → Ein bereits befülltes Gerät mit einer versteckten Nadel, mit dem einmal wöchentlich injiziert wird<br />
        → Kann zu jeder Tageszeit vor oder nach dem Essen verwendet werden
      </p>
    </ImageAndText>

    <Collapse parent="">
      <template v-slot:heading>
        Weitere Hinweise und Beipackzettel
      </template>
      <template v-slot:content>
        <p>Diese Webseite soll Sie bei der Anwendung des BYDUREON<sup>®</sup> BCise<sup>®</sup> bestmöglich unterstützen und Ihnen bei der sicheren Handhabung des Gerätes behilflich sein. Bitte denken Sie jedoch daran, dass diese Webseite nicht dafür ausgelegt ist, das Gespräch mit Ihrem Arzt über Ihre Behandlung zu ersetzen. </p>
        <p>Diese Webseite ersetzt nicht den Beipackzettel, der mit Ihrer Arzneimittelverpackung geliefert wurde. Lesen Sie diesen sorgfältig durch, bevor Sie das Arzneimittel verwenden. Fragen Sie Ihren Apotheker nach einer Kopie. <!--oder laden Sie den Beipackzettel hier herunter:--></p>
        <p>
          <Download link="https://www.patienteninfo-service.de/a-z-liste/b/bydureon-bcise/">
            <template v-slot:name>
              Beipackzettel BYDUREON<sup>®</sup> BCise<sup>®</sup>
            </template>
            <template v-slot:type>
              PDF 106 KB
            </template>
          </Download>
        </p>
      </template>
    </Collapse>

    <br />

    <Video 
      :sources="videoSources"
      :poster="require('../assets/video-poster.png')"
      containerId="video">
      Erfahren Sie, wie Sie Ihren BYDUREON<sup>®</sup> BCise<sup>®</sup> Pen verwenden können:
    </Video>

    <ThreeColumns>
      <template v-slot:heading-one>1. Mischen</template>
      <template v-slot:content-one>
        <p>
          Schütteln Sie das verschlossene Gerät mit der orangefarbenen Kappe in Richtung Decke weisend 
          mindestens 15 Sekunden lang in einer Auf- und Abbewegung, bis das Arzneimittel gut gemischt ist. 
          Überprüfen Sie die Mischung, indem Sie den Pen gegen das Licht halten und durch beide Seiten und 
          den unteren Rand des Fensters schauen. Wenn es nicht gut vermischt ist, wiederholen Sie den Mischvorgang.
        </p>
      </template>
      <template v-slot:heading-two>2. Entsperren</template>
      <template v-slot:content-two>
        <p>
          Das Arzneimittel muss vor dem Entriegeln vollständig vermischt sein. Entriegeln Sie das Gerät durch 
          Drehen des Knopfes aus der gesperrten in die entsperrte Position und schrauben Sie die orangefarbene 
          Kappe ab. Die Kappe nicht abziehen! Sie hören ein Klickgeräusch, wenn die Kappe in die falsche 
          Richtung gedreht wird.
        </p>
      </template>
      <template v-slot:heading-three>3. Injizieren</template>
      <template v-slot:content-three>
        <p>
          Drücken Sie das Gerät gegen Ihre Haut und halten Sie es 15 Sekunden lang fest, um die volle Dosis 
          zu erhalten. Sie hören ein Klicken, wenn die Injektion beginnt.
        </p>
        <p>
          Während der Injektion wird im Fenster ein orangefarbener Stab angezeigt, während das Arzneimittel 
          aus dem Gerät gedrückt wird.
        </p>
      </template>
    </ThreeColumns>

    <HighlightSection :imgSrc="require('../assets/brochure.jpg')" :imgSrc2x="require('../assets/brochure@2x.jpg')" :imgSrc3x="require('../assets/brochure@3x.jpg')">
      <h2>Patientenbroschüre</h2>
      <p>Für einen erfolgreichen Start mit BYDUREON<sup>®</sup> BCise<sup>®</sup> können Sie sich hier die Patientenbroschüre herunterladen. Dort erhalten Sie alle wichtigen und nützlichen Informationen. </p>
      <p>
        <Download :link="require('../assets/brochure.pdf')">
            <template v-slot:name>
              Patientenbroschüre BYDUREON<sup>®</sup> BCise<sup>®</sup>
            </template>
            <template v-slot:type>
              PDF 754 KB
            </template>
          </Download>
      </p>
    </HighlightSection>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue';
import Heading from '@/components/Heading.vue';
import BigImage from '@/components/BigImage.vue';
import ImageAndText from '@/components/ImageAndText.vue';
import Collapse from '@/components/Collapse.vue';
import HighlightSection from '@/components/HighlightSection.vue';
import Video from '@/components/Video.vue';
import ThreeColumns from '@/components/ThreeColumns.vue';
import Download from '@/components/Download.vue';

export default {
  name: 'home',
  components: {
    Hero,
    Heading,
    BigImage,
    ImageAndText,
    Collapse,
    HighlightSection,
    Video,
    ThreeColumns,
    Download
  },
  data() {
    return {
      videoSources: [
        {src: "/Bydureon_HowToUse.mp4", type: "video/mp4"}
      ],
      loginpath: '/musteranforderung'
    }
  },
}
</script>

<style lang="scss">
.productform-wrapper {
  font-family: Lexia, sans-serif;
  font-size: 20px;
  background: $orange;
  width: 100%;
  padding: .1em 0;

  @media (max-width: 767px) {
    padding: .3em 0;
  }

  a {
    font-weight: bold;
    font-style: italic;
    color: $graphite;
    font-size: 17px;

    img {
      margin: -4px 0 0 4px;
      width: 20px;
    }
  }

  .col-16:first-child {
    @media (max-width: 767px) {
      margin-bottom: 1.7em;
    }
  }
}
</style>