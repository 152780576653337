
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Accordion extends Vue {
  private uniqueId!: string;

  constructor() {
    super();
    this.uniqueId = '_' + Math.random().toString(36).substr(2, 9);
  }

  getAccordionId() {
    return 'acc' + this.uniqueId;
  }

  mounted() {
    this.$emit('created', this.getAccordionId());
  }
}
