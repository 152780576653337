
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageAndText extends Vue {
  @Prop() private imgSrc!: string;
  @Prop() private imgSrc2x!: string;

  getSrcSets() {
    return this.imgSrc2x + ' 2x, ' + this.imgSrc + ' 1x';
  }
}
