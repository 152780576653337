
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Hero extends Vue {
  @Prop() private hash!: string;
  @Prop() private imgSrc!: string;
  @Prop() private imgSrc2x!: string;
  @Prop() private link!: string;

  getSrcSets() {
    return this.imgSrc2x + ' 2x, ' + this.imgSrc + ' 1x';
  }

  scrollToElement() {
    let el = document.getElementById(this.hash);
    let position = this.getPosition(el);
    this.scrollWindowTo(position.y - 20, 200);
  }

  scrollWindowTo(to: any, duration: any) {
    let self = this
    if (duration <= 0) return;
    var difference = to - window.scrollY;
    var perTick = difference / duration * 10;

    setTimeout(function() {
      window.scroll(0, window.scrollY + perTick)
      if (window.scrollY === to) return;
      self.scrollWindowTo(to, duration - 10);
    }, 10);
  }

  getPosition(el: any) {
    var xPos = 0;
    var yPos = 0;

    while (el) {
      if (el.tagName == "BODY") {
        var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        var yScroll = el.scrollTop || document.documentElement.scrollTop;

        xPos += (el.offsetLeft - xScroll + el.clientLeft);
        yPos += (el.offsetTop - el.clientTop);
      } else {
        xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
        yPos += (el.offsetTop - el.clientTop);
      }

      el = el.offsetParent;
    }

    return {
      x: xPos,
      y: yPos
    };
  }
}
