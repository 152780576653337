class Helper {
  private static instance: Helper | null = null;

  private constructor() { }

  public static getInstance(): Helper {
    if (this.instance === null) {
      this.instance = new Helper();
    }

    return this.instance;
  }

  /*
   * Dispatch "cookiechange" custom event on cookie changes
   */
  cookieChangeHandler() {
    const matchPattern = /(?:wscrCookieConsent?)[^;]+/;
    let wscrCookieState: string = "";

    let lastCookie = document.cookie;
    // rename document.cookie to document._cookie, and redefine document.cookie
    const expando = "_cookie";
    const expandoIsReady = Object.prototype.hasOwnProperty.call(
      Document.prototype,
      expando
    );

    if (!expandoIsReady) {
      let nativeCookieDesc: any = Object.getOwnPropertyDescriptor(
        Document.prototype,
        "cookie"
      );

      Object.defineProperty(Document.prototype, expando, nativeCookieDesc);
      Object.defineProperty(Document.prototype, "cookie", {
        enumerable: true,
        configurable: true,
        get() {
          return this[expando];
        },
        set(value) {
          this[expando] = value;
          let cookie = this[expando];

          // match "wscrCookieConsent" from cookies
          const wscrOldValueMatch = lastCookie.match(matchPattern);
          const wscrNewValueMatch = cookie.match(matchPattern);

          const wscrOldValue =
            wscrOldValueMatch !== null ? wscrOldValueMatch[0] : "";
          const wscrNewValue =
            wscrNewValueMatch !== null ? wscrNewValueMatch[0] : "";

          // check the wscrCookieConsent cookie change
          // dispatch the "cookiechange" event only if "wscrCookieConsent" has changed (ignore changes from other cookies)
          // keep a local state in "wscrCookieState" and compare it to "wscrNewValue" to prevent dispatching the event when the cookie change is triggered by other cookies and wscrOldValue and wscrNewValue are still different
          if (
            wscrOldValue !== wscrNewValue &&
            wscrNewValue !== wscrCookieState &&
            wscrNewValue.includes("visitor=")
          ) {
            wscrCookieState = wscrNewValue;
            try {
              // dispatch cookie-change messages to other same-origin tabs/frames
              let detail = { oldValue: lastCookie, newValue: cookie };
              this.dispatchEvent(
                new CustomEvent("cookiechange", {
                  detail: detail
                })
              );
              // channel.postMessage(detail);
            } finally {
              lastCookie = cookie;
            }
          }
        }
      });
    }
    /**
     * listen cookie-change messages from other same-origin tabs/frames
     *
     * <!> A polyfill for Safari is needed, otherwise the error blocks the
     * application flow on staging and production due to additional rules.
     */
    // const channel = new BroadcastChannel("cookie-channel");
    // channel.onmessage = (e: any) => {
    //   lastCookie = e.data.newValue;
    //   document.dispatchEvent(
    //     new CustomEvent("cookiechange", {
    //       detail: e.data
    //     })
    //   );
    // };
  }
}

export default Helper.getInstance();
