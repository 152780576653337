
import { Component, Vue } from 'vue-property-decorator';
import Navigation from './components/layout/Navigation.vue';
import Footer from './components/layout/Footer.vue';
import Helper from '@/util/Helper'

@Component({
  components: {
    Navigation,
    Footer
  },
})
export default class App extends Vue {
  created() {
    Helper.cookieChangeHandler()
  }
}
