
import { Component, Prop, Vue } from 'vue-property-decorator';
import videojs from 'video.js'

@Component
export default class Video extends Vue {
  
  @Prop()
  private sources!: any[];

  @Prop()
  private poster!: string;

  @Prop()
  private containerId!: string;

  private uniqueId!: string;
  private player?: any = null;

  constructor() {
    super();
    this.uniqueId = 'vjs_' + Math.random().toString(36).substr(2, 9);
  }

  mounted() {
    this.player = videojs(document.getElementById(this.uniqueId)!, {
      fluid: true
    });
  }
}
