
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HighlightSection extends Vue {
  @Prop() private imgSrc!: string;
  @Prop() private imgSrc2x!: string;
  @Prop() private imgSrc3x!: string;

  getSrcSets() {
    return this.imgSrc + ' 1x, ' + this.imgSrc2x + ' 2x, ' + this.imgSrc3x + ' 3x';
  }
}
