
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Collapse extends Vue {
  @Prop()
  private parent!: string;

  private uniqueId!: string;
  private open!: boolean;

  constructor() {
    super();
    this.uniqueId = '_' + Math.random().toString(36).substr(2, 9);
    this.open = false;
  }

  buttonHeadingClasses() {
    let classes = [''];
    if ( !this.parentExists() ) {
      classes.push('centered');
    }
    return classes.join(' ');
  }

  containerClasses() {
    let classes = ['container'];
    if ( this.parentExists() ) {
      classes.push('p-0');
    }
    return classes.join(' ');
  }

  sectionClasses() {
    let classes = ['content-section'];
    if ( this.parentExists() ) {
      classes.push('no-mobile-margin');
    }
    return classes.join(' ');
  }

  collapseClasses() {
    let classes = ['collapse', 'collapse-content'];
    if ( !this.parentExists() ) {
      classes.push('centered');
    }
    return classes.join(' ');
  }

  chevrClasses() {
    let classes = ['chevr'];
    if ( this.open ) {
      classes.push('up');
    }
    return classes.join(' ');
  }

  getElementId() {
    return this.parent + this.uniqueId;
  }

  getHeadingId() {
    return this.parent + this.uniqueId + '_head';
  }

  toggleChevr() {
    if ( !this.parentExists() ) {
      this.open = !this.open;
    }
  }

  getParentId() {
    if ( !this.parentExists() ) {
      return '';
    }

    return '#' + this.parent;
  }

  parentExists() {
    return this.parent != "" && this.parent != undefined;
  }
}
