import { Storage } from '@/util/Storage'

class Matomo {
  private static instance?: Matomo;
  private static tracker: any = null;

  constructor() {
    document.addEventListener("cookiechange", Matomo.handleConsentChange);
  }

  public init() {
    if (Matomo.getTrackingConsent()) {
      Matomo.optUserIn();
    } else {
      Matomo.optUserOut();
    }
  }

  public static getTrackingConsent() {
    const wscrCookies = Storage.get("wscrCookieConsent", false, true);
    let isCookieConsentGiven = false;

    if (wscrCookies) {
      // check if level 3 cookies are set to "true"
      return isCookieConsentGiven = wscrCookies.split("&")[2].endsWith("true");
    }

    return false;
  }

  private static parseConsentCookie(changedCookies: string) {
    if (changedCookies) {
      const cookies = changedCookies.split(";");
      const wscrCookies = cookies.find(cookie =>
        cookie.trim().startsWith("wscr")
      );

      if (wscrCookies) {
        // check if level 3 cookies are set to "true"
        const cookieValue = wscrCookies.split("&")[2].endsWith("true");
        return cookieValue;
      }
    }

    return false;
  }

  public static handleConsentChange(ev: Event) {
    const previousConsentValue = Matomo.parseConsentCookie(
      (ev as CustomEvent).detail.oldValue
    );
    const currentConsentValue = Matomo.getTrackingConsent();

    if (previousConsentValue !== currentConsentValue) {
      if (currentConsentValue) {
        Matomo.optUserIn();
      } else {
        Matomo.optUserOut();
      }
    }
  }

  public static optUserIn() {
    // @ts-ignore
    const paq = window._paq || [];
    paq.push([
      function () {
        //@ts-ignore
        if (this.isUserOptedOut()) {
          //@ts-ignore
          this.forgetUserOptOut();
        }
      }
    ])
    paq.push(["setConsentGiven"]);
    paq.push(["setCookieConsentGiven"]);
  }

  public static optUserOut() {
    //@ts-ignore
    let paq = window._paq || [];
    paq.push(["forgetConsentGiven"]);
    paq.push(["forgetCookieConsentGiven"]);
    paq.push(["optUserOut"]);
  }

  public static getInstance(): Matomo {
    if (!this.instance) {
      this.instance = new Matomo();
    }
    return this.instance;
  }
}

export default Matomo.getInstance();
