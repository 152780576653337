import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'video.js'
import 'video.js/dist/video-js.min.css'
import './sass/app.scss';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Matomo from '@/plugins/matomo'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// Init Matomo
Matomo.init();
