import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'imprint',
    // route level code-splitting
    // this generates a separate chunk (imprint.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue')
  },
  {
    path: '/datenschutz',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/nutzungsbedingungen',
    name: 'tou',
    component: () => import(/* webpackChunkName: "tou" */ '../views/Tou.vue')
  },
  {
    path: '/cookie-policy',
    name: 'cookiepolicy',
    component: () => import(/* webpackChunkName: "tou" */ '../views/Cookiepolicy.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import(/* webpackChunkName: "kontakt" */ '../views/Kontakt.vue')
  },
  // {
  //   path: '/musteranforderung',
  //   name: 'musteranforderung',
  //   component: () => import(/* webpackChunkName: "musteranforderung" */ '../views/Produktprobe.vue')
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/DocCheck.vue')
  // },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "pnf" */ '../views/PageNotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
