
import { Component, Prop, Vue } from 'vue-property-decorator';
import Collapse from './Collapse.vue';
import Accordion from './Accordion.vue';

@Component({
  components: {
    Collapse,
    Accordion
  }
})
export default class ThreeColumns extends Vue {

  private windowWidth!: number;

  private accordionId!: string;

  constructor() {
    super();
    this.windowWidth = document.documentElement.clientWidth;
    this.accordionId = "";
  }

  mounted() {
    let self = this
    Vue.nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
    })
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  }

  getWindowWidth(e: any = null) {
    this.windowWidth = document.documentElement.clientWidth;
  }

  isMobile() {
    return this.windowWidth < 768;
  }

  accordionCreated(id: string) {
    this.accordionId = id;
  }
}
